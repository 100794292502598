<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
export default {
  page: {
    title: "Contact Users List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      pageSize: 80, // Nombre d'éléments à afficher sur chaque page
      currentPage: 0, // La page actuelle
      role: "",
      usersList: [],
      roleList: [],
      searchQuery: "",
      showModal: false,
      spinner: false,
      activeFilter: "all",
      filteredArticles: [],
      title: "liste des utilisateurs",
      startDate: "",
      endDate: "",
      items: [
        {
          text: "Awards",
          href: "/award/awardList",
        },
        {
          text: this.$route.params.awardName,
          href: "/award/awardDetail/" + this.$route.params.id,
        },
        {
          text: "Transactions",
          active: true,
        },
      ],
    };
  },
  methods: {
    goToPage(page) {
      // Vérifier si la page demandée est valide
      if (page >= 0 && page <= this.pageCount) {
        this.currentPage = page;
      }
    },
    filterArticles(filter) {
      if (filter === "all") {
        this.filteredArticles = this.usersList;
      } else if (filter === "SUCCESSFUL") {
        this.filteredArticles = this.usersList.filter(
          (article) => article.state === "SUCCESSFUL"
        );
      } else if (filter === "PENDING") {
        this.filteredArticles = this.usersList.filter(
          (article) => article.state === "PENDING"
        );
      } else if (filter === "CANCELED") {
        this.filteredArticles = this.usersList.filter(
          (article) => article.state === "CANCELED"
        );
      } else if (filter === "FAILED") {
        this.filteredArticles = this.usersList.filter(
          (article) => article.state === "FAILED"
        );
      }
      this.filter = filter; // Mettre à jour l'état du filtre actif

      // Mettre à jour le filtre actif
      this.activeFilter = filter;
    },

    formatNumberWithSpaces(number) {
      // Convertit le nombre en chaîne de caractères
      const numberString = number.toString();

      // Utilise une expression régulière pour ajouter un espace après chaque groupe de trois chiffres
      const formattedNumber = numberString.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        " "
      );

      return formattedNumber;
    },
  },
  computed: {
    filteredUsersList() {
      if (!this.startDate || !this.endDate) {
        if (this.searchQuery) {
          return this.filteredArticles
            .slice()
            .reverse()
            .filter((user) => {
              const fullName = user.customer_name;
              return fullName
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase());
            });
        }
        return this.filteredArticles.slice().reverse();
      }

      const start = new Date(this.startDate).getTime();
      const end = new Date(this.endDate).getTime();

      return this.filteredArticles
        .slice()
        .reverse()
        .filter((transaction) => {
          const createdAt = new Date(transaction.startPayDate).getTime();
          const title = transaction.customer_name.toLowerCase();
          const searchQuery = this.searchQuery.toLowerCase();

          return (
            createdAt >= start &&
            createdAt <= end &&
            title.includes(searchQuery)
          );
        });
    },

    pages() {
      // Générer une liste de nombres de pages à afficher dans la pagination
      let pages = [];
      for (let i = 0; i <= this.pageCount - 1; i++) {
        pages.push(i);
      }
      return pages;
    },
    pageCount() {
      return Math.ceil(this.filteredUsersList.length / this.pageSize);
    },
    paginatedVideos() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredUsersList.slice(start, end);
    },
  },
  mounted() {
    this.spinner = true;
    Api.get(`/voting/rest/payment/all-payment/${this.$route.params.id}`)
      .then((res) => {
        this.spinner = false;
        this.usersList = res.data.content;
        console.log(this.usersList);
        this.filterArticles("all");

        console.log(this.pages.length);
      })
      .catch((error) => {
        this.spinner = false;
        this.process = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <br />
    <h4>
      {{
        this.formatNumberWithSpaces(filteredUsersList.length)
      }}
      transaction<span v-if="filteredUsersList.length > 1">s</span>
    </h4>

    <div class="row mb-3">
      <div class="col-lg-5">
        <br /><br />
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Rechercher une transaction"
            style="border-radius: 8px"
            v-model="searchQuery"
          />
        </div>
      </div>

      <div class="col-lg-7">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label"
                >Date de début</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="date"
                  class="form-control"
                  v-model="startDate"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="projectname" class="col-form-label"
                >Date de fin</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="date"
                  class="form-control"
                  v-model="endDate"
                  :min="startDate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div style="display: flex; justify-content: center; align-items: center">
        <button
          @click="filterArticles('all')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'all' }"
        >
          Toute
        </button>
        <button
          @click="filterArticles('SUCCESSFUL')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'SUCCESSFUL' }"
        >
          Réussite
        </button>
        <button
          @click="filterArticles('PENDING')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'PENDING' }"
        >
          En attente
        </button>
        <button
          @click="filterArticles('CANCELED')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'CANCELED' }"
        >
          Annulée
        </button>
        <button
          @click="filterArticles('FAILED')"
          class="btn"
          :class="{ 'c2play-primary': activeFilter === 'FAILED' }"
        >
          Echouée
        </button>
      </div>
    </div>

    <br />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 70px">#</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Numéro</th>
                    <th scope="col">Opérateur</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Date de debut</th>
                    <th scope="col">Date de fin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="transaction in paginatedVideos"
                    :key="transaction.id"
                  >
                    <td>
                      <div class="avatar-xs">
                        <span class="avatar-title rounded-circle c2play-primary"
                          >{{ transaction.customer_name.charAt(0).toUpperCase()
                          }}{{
                            transaction.customer_name.charAt(1).toUpperCase()
                          }}</span
                        >
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-1">
                        {{ transaction.customer_name }}
                      </h5>
                    </td>
                    <td>{{ transaction.transaction_amount }}</td>
                    <td>
                      <span>{{ transaction.customer_phone_number }}</span>
                    </td>
                    <td>
                      <span v-if="transaction.transaction_operator == 'MCP'">
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAd1zAvZeKuswoqeZjgGKeNy1AFj-35RgzB26aR-yM1GzxClo44I5Fn18njvSECRa6QHw&usqp=CAU"
                          alt="Card image"
                          style="height: 20px; width: 40px"
                        />
                      </span>
                      <span v-if="transaction.transaction_operator == 'CM_OM'">
                        <img
                          src="https://logos-marques.com/wp-content/uploads/2021/07/Orange-Money-emblem.png"
                          alt="Card image"
                          style="height: 20px; width: 40px"
                        />
                      </span>
                      <span
                        v-if="transaction.transaction_operator == 'CM_MOMO'"
                      >
                        <img
                          src="https://e7.pngegg.com/pngimages/65/753/png-clipart-mobile-payment-mtn-group-mobile-phones-money-cave-collapse-label-text.png"
                          alt="Card image"
                          style="height: 20px; width: 40px"
                        />
                      </span>
                      <span v-if="transaction.transaction_operator == 'CARD'">
                        <img
                          src="https://www.reussir-mon-ecommerce.fr/wp-content/uploads/2016/03/logo-visa-carte-1.png"
                          alt="Card image"
                          style="height: 20px; width: 40px"
                        />
                      </span>
                    </td>
                    <td>
                      <span
                        v-if="transaction.state == 'SUCCESSFUL'"
                        class="c2play-primary badge"
                      >
                        Réussie</span
                      >
                      <span
                        v-if="transaction.state == 'CANCELED'"
                        class="bg-warning badge"
                        >Annulée</span
                      >
                      <span
                        v-if="transaction.state == 'FAILED'"
                        class="bg-danger badge"
                        >Echouée</span
                      >
                      <span
                        v-if="transaction.state == 'PENDING'"
                        class="bg-secondary badge"
                        >En attente</span
                      >
                    </td>
                    <td>
                      {{ new Date(transaction.startPayDate).toLocaleString() }}
                    </td>
                    <td>
                      {{ new Date(transaction.endPayDate).toLocaleString() }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                style="text-align: center; font-size: 3em"
                v-if="spinner == true"
              >
                <b-spinner variant="success" role="status"></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <nav
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        v-if="pages.length > 1"
      >
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 0 }">
            <button
              class="page-link"
              style="margin: 5px; font-size: 15px; border-radius: 20px 0 0 20px"
              @click="goToPage(currentPage - 1)"
            >
              Previous
            </button>
          </li>
          <div
            style="max-width: 800px; justify-content: center"
            class="display"
          >
            <li v-for="page in pages" :key="page" class="page-item">
              <button
                class="page-link"
                :class="{ active2play: currentPage === page }"
                style="
                  height: 45px;
                  width: 45px;
                  border-radius: 50%;
                  margin: 5px;
                "
                @click="goToPage(page)"
              >
                {{ page + 1 }}
              </button>
            </li>
          </div>
          <li
            class="page-item"
            :class="{ disabled: currentPage === pageCount - 1 }"
          >
            <button
              class="page-link"
              style="margin: 5px; font-size: 15px; border-radius: 0 20px 20px 0"
              @click="goToPage(currentPage + 1)"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </Layout>
</template>
